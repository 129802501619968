import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router';
import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useDispatch } from 'react-redux';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
//import CustomGraph from 'pages/AuthorQuestion/Graphing/CustomGraph';
import { IoIosUndo, IoIosRedo, IoMdMove } from "react-icons/io";
import { RxReset } from "react-icons/rx";
import { TbPoint } from "react-icons/tb";
import { FaRegCircle } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import uuid from 'react-uuid';
import JXG from 'jsxgraph';

const initialData = {
    points: [],
    tools: []
};

interface graphTypeObject {
    type: string;
    points: [number, number];
};


interface graphingeObject {
    id: string;
    type: string;
    coords?: {},
    subElement?: boolean,
    subElementIds?: {
        startPoint?: any,
        endPoint?: any
    }
};


const PreviewGraphingUnit = ({
    currentQuestion,
    showAnswer = undefined,
    setShowAnswers = undefined,
    parentMode = 'assessment',
    editMode = false,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [localShowAnswer, setLocalShowAnswer] = useState(false);
    const boardRef = useRef<HTMLDivElement | null>(null);

    const [data, setData] = useState({
        ...initialData,
    });
    console.log(currentQuestion, " CUrrent Question");

    const [buttonOption, setButtonOption] = useState('');
    const [optionItems, setOptionItems] = useState(currentQuestion?.options);
    // const [graphPoints, setgraphPoints]=useState<[number,number][]>([]);
    // const [graphAnswers, setgraphAnswers]=useState<graphTypeObject[]>([]);
    const [clickPoints, setClickPoints] = useState<[number, number][]>([]);
    const [selectedButton, setSelectedButton] = useState(optionItems?.toolbar?.default_tool);
    const [graphData, setGraphData] = useState<graphingeObject[]>([]);
    const [subElementPos, setSubElementPos] = useState([]);
    const [redoData, setRedoData] = useState([]);

    const handleBackToEditClick = () => {
        dispatch(setClosePreview());
    };

    console.log(optionItems?.toolbar?.default_tool);

    console.log(optionItems, "Option Items");
    const handleGoToEditClick = () => {
        dispatch(
            setTypeQuestionOpenInEditMode({
                type: currentQuestion?.type,
                id: currentQuestion?.id,
            })
        );
        navigate(`/edit-subquestion/${currentQuestion.id}`);
    };

    console.log(currentQuestion, " Current Question");
    const handleReset = () => {
        //setgraphAnswers([]);
        //setgraphPoints([]);
        setData(initialData);
        setButtonOption('');
        setGraphData([]);
        setSelectedButton(optionItems?.toolbar?.tools?.default_tool);

    }

    useEffect(() => {

        if (boardRef.current) {
            const board = JXG.JSXGraph.initBoard(boardRef?.current, {
                boundingbox: [optionItems["canvas"]["x_min"], parseFloat(optionItems["canvas"]["y_max"]), optionItems["canvas"]["x_max"], optionItems["canvas"]["y_min"]],
                axis: false,

                keepaspectratio: false, // Keeps the bounding box aspect ratio
                showNavigation: false, // Hides navigation to avoid clutter
                grid: true, // Optional: Adds a grid to make the bounding clearer
                showCopyright: false,
                pan: { enabled: true },

            });
            board.create('axis', [[0, 0], [1, 0]], {

                ticks: {
                    visible: true,
                    majorHeight: 10,   // Height of the major ticks
                    minorHeight: 5,    // Height of the minor ticks
                    majorTicks: true,  // Enable major ticks
                    minorTicks: false, // Disable minor ticks
                    drawLabels: true,
                    strokeColor: 'black',
                    label: {
                        visible: true,
                        offset: [-5, -15], // Adjust the position of the tick labels
                    },
                    tickDistance: 1,        // Distance between major ticks (step size)
                },
            });
            board.create('axis', [[0, 0], [0, 1]], {
                ticks: {
                    visible: true,
                    majorHeight: 10,
                    minorHeight: 5,
                    majorTicks: true,
                    minorTicks: false,
                    drawLabels: true,
                    label: {
                        visible: true,
                        offset: [-20, 0],
                    },
                    tickDistance: 1, // Step size of 1 for the y-axis as well
                    strokeColor: 'black'
                },
            });




            if (showAnswer) {
                const displayGraphPoints = [...currentQuestion?.correct_answer?.valid_response?.points];
                console.log(displayGraphPoints, " Graph Points");
                //show Correct Answers
                displayGraphPoints.forEach(graphInfo => {
                    if (graphInfo?.type === "Point") {
                        const newPoint = [graphInfo?.coords[0], graphInfo?.coords[1]];
                        const gPoint = board.create("point", newPoint, {
                            name: '',
                            size: 2,
                            color: 'blue',
                        });
                    } else {
                        //graphShapes(board, graphInfo);
                        const starttID = graphInfo?.subElementIds?.startPoint;
                        const endID = graphInfo?.subElementIds?.endPoint;

                        const firstPointObj = displayGraphPoints?.find(gdInfo => gdInfo?.id === starttID);
                        const secondPointObj = displayGraphPoints?.find(gdInfo => gdInfo?.id === endID);
                        console.log(firstPointObj, secondPointObj, " First & Second Points");

                        const firstPoint = firstPointObj?.coords;
                        const secondPoint = secondPointObj?.coords;
                        const newPoints = [[firstPoint[0], firstPoint[1]], [secondPoint[0], secondPoint[1]]];
                        console.log(newPoints, " Object New POints", graphInfo.type);
                        if (graphInfo.type === "Line") {
                            console.log("Entering into Line");
                            board.create("line", newPoints, {
                                strokeWidth: 2,
                                strokeColor: 'blue',
                                lastArrow: true,
                                firstArrow: true
                            });
                        }
                        if (graphInfo.type === "Circle") {
                            board.create("circle", newPoints, {
                                strokeWidth: 2,
                                strokeColor: 'blue',

                            });
                        }
                        if (graphInfo.type === "Segment") {
                            board.create("segment", newPoints, {
                                strokeWidth: 2,
                                strokeColor: 'blue',

                            });
                        }
                        if (graphInfo.type === "Segment") {
                            board.create("segment", newPoints, {
                                strokeWidth: 2,
                                strokeColor: 'blue',

                            });
                        }
                        if (graphInfo.type === "Vector") {

                            const pointA = board.create("point", [firstPoint[0], firstPoint[1]], { name: "A", visible: false });
                            const pointB = board.create("point", [secondPoint[0], secondPoint[1]], { name: "B", visible: false });

                            const controlPoint = board.create("point", [0, 4], { name: "Control", visible: false });

                            // Create the quadratic Bézier curve (simulating a curved vector)
                            const curve = board.create("curve", [
                                (t: number) => (1 - t) * (1 - t) * pointA.X() + 2 * (1 - t) * t * controlPoint.X() + t * t * pointB.X(),
                                (t: number) => (1 - t) * (1 - t) * pointA.Y() + 2 * (1 - t) * t * controlPoint.Y() + t * t * pointB.Y(),
                                -0.5, 1.5
                            ], {
                                strokeColor: "green",
                                strokeWidth: 2
                            });
                        }

                        if (graphInfo.type === "Ray") {
                            board.create("line", newPoints, {
                                strokeWidth: 2,
                                strokeColor: 'blue',
                                lastArrow: true,
                                straightFirst: false,   // Do not extend in the negative direction
                                straightLast: true

                            });
                        }

                        if (graphInfo.type === "Parabola") {
                            board.create("line", newPoints, {
                                strokeWidth: 2,
                                strokeColor: 'blue',


                            });
                        }
                    }
                });
                //Show Local Answers


            }
            const displayGraphData = [...graphData];
            console.log(displayGraphData, " Display graph data");
            displayGraphData.forEach(graphInfo => {

                if (graphInfo?.type === "Point") {
                    const newPoint = [graphInfo?.coords[0], graphInfo?.coords[1]];
                    let localAnswercolor = 'blue';
                    //let localAnswername: string;
                    if (showAnswer) {
                        localAnswercolor = 'red';
                        //localAnswername = <RxCross2 />;
                        const exists = checkPointExists(newPoint);
                        if (exists) {
                            localAnswercolor = 'green';
                            //localAnswername = <TiTick />;
                        }

                    }

                    const gPoint = board.create("point", newPoint, {
                        name: '',
                        size: 2,
                        color: localAnswercolor,
                    });
                 
                } else {
                    //graphShapes(board, graphInfo);
                    const starttID = graphInfo?.subElementIds?.startPoint;
                    const endID = graphInfo?.subElementIds?.endPoint;

                    const firstPointObj = graphData?.find(gdInfo => gdInfo?.id === starttID);
                    const secondPointObj = graphData?.find(gdInfo => gdInfo?.id === endID);
                    console.log(firstPointObj, secondPointObj, " First & Second Points");

                    const firstPoint = firstPointObj?.coords;
                    const secondPoint = secondPointObj?.coords;
                    const newPoints = [[firstPoint?.[0], firstPoint?.[1]], [secondPoint?.[0], secondPoint?.[1]]];
                    console.log(newPoints, " Object New POints", graphInfo.type);
                    if (graphInfo.type === "Line") {
                        console.log("Entering into Line");
                        board.create("line", newPoints, {
                            strokeWidth: 2,
                            strokeColor: 'blue',
                            lastArrow: true,
                            firstArrow: true
                        });
                    }
                    if (graphInfo.type === "Circle") {
                        board.create("circle", newPoints, {
                            strokeWidth: 2,
                            strokeColor: 'blue',

                        });
                    }
                    if (graphInfo.type === "Segment") {
                        board.create("segment", newPoints, {
                            strokeWidth: 2,
                            strokeColor: 'blue',

                        });
                    }
                    if (graphInfo.type === "Segment") {
                        board.create("segment", newPoints, {
                            strokeWidth: 2,
                            strokeColor: 'blue',

                        });
                    }
                    if (graphInfo.type === "Vector") {

                        const pointA = board.create("point", [firstPoint[0], firstPoint[1]], { name: "A", visible: false });
                        const pointB = board.create("point", [secondPoint[0], secondPoint[1]], { name: "B", visible: false });

                        const controlPoint = board.create("point", [0, 4], { name: "Control", visible: false });

                        // Create the quadratic Bézier curve (simulating a curved vector)
                        const curve = board.create("curve", [
                            (t: number) => (1 - t) * (1 - t) * pointA.X() + 2 * (1 - t) * t * controlPoint.X() + t * t * pointB.X(),
                            (t: number) => (1 - t) * (1 - t) * pointA.Y() + 2 * (1 - t) * t * controlPoint.Y() + t * t * pointB.Y(),
                            -0.5, 1.5
                        ], {
                            strokeColor: "green",
                            strokeWidth: 2
                        });
                    }

                    if (graphInfo.type === "Ray") {
                        board.create("line", newPoints, {
                            strokeWidth: 2,
                            strokeColor: 'blue',
                            lastArrow: true,
                            straightFirst: false,   // Do not extend in the negative direction
                            straightLast: true

                        });
                    }

                    if (graphInfo.type === "Parabola") {
                        board.create("line", newPoints, {
                            strokeWidth: 2,
                            strokeColor: 'blue',


                        });
                    }
                }
            });




            const handleBoardDown = (event: MouseEvent) => {
                if (selectedButton !== "Move") {
                    const coords = board.getUsrCoordsOfMouse(event);
                    const x = coords[0];
                    const y = coords[1];

                    const newPoint: [number, number] = [x, y];

                    board.create('point', newPoint, {
                        name: '', // Optionally name the point
                        size: 2,  // Set point size
                        color: 'blue', // Set point color
                    });
                    let gObj;
                    let gID = uuid();
                    if (selectedButton === "Point") {
                        gObj = { id: gID, type: "Point", coords: newPoint };
                    } else {
                        gObj = { id: gID, type: "Point", coords: newPoint, subElement: true };
                        subElementPos.push(gID);
                        setSubElementPos((prevPos) => {
                            const updatedPos = [...prevPos, gID];

                            return updatedPos;
                        });

                    }
                    console.log(gObj, "Point Object");
                    setGraphData((prevData) => {
                        const updatedPoints = [...prevData, gObj];
                        //  updatePointsChange(updatedPoints);
                        return updatedPoints;
                    });


                    setClickPoints((prevPoints) => {
                        const updatedPoints = [...prevPoints, newPoint];
                        console.log(updatedPoints, " Updated Points");
                        console.log(selectedButton, " Selected Button");
                        // If there are two points, create a line and reset points
                        if (updatedPoints.length === 2) {
                            const graphtype = selectedButton;
                            if (graphtype !== "undefined" && graphtype !== "Point") {
                                console.log('Entering');
                                let gtObj = {
                                    id: uuid(),
                                    type: selectedButton,
                                    subElementIds: {
                                        startPoint: subElementPos[0],
                                        endPoint: gID
                                    }
                                }
                                console.log(gtObj, "Graph Type Object with two sub elements");
                                setGraphData((prevData) => {
                                    const updatedData = [...prevData, gtObj];
                                    //  updatePointsChange(updatedData);
                                    return updatedData;
                                });
                                setSubElementPos([]);
                                console.log(graphData, " Graph Data");
                                //  console.log(graphAnswers, " Graph Answers");
                            }
                            return []; // Reset points after creating a line
                        }
                        return updatedPoints; // Otherwise, add the new point
                    });
                } else {
                    const coords = board.getUsrCoordsOfMouse(event);
                    console.log('Moving', coords);
                }

            }

            board.on('down', handleBoardDown);
            return () => {
                board.off('down', handleBoardDown);
            };





        }
    }, [graphData, selectedButton, showAnswer]);

    const checkPointExists = (coords) => {
        console.log(coords, " Co Ordinates");

        const displayGraphPoints = [...currentQuestion?.correct_answer?.valid_response?.points];
        // console.log(displayGraphPoints, " Display graph Points");

        return displayGraphPoints.some(point => {

            const roundedPoint1 = point?.coords?.map(coord => parseFloat(coord.toFixed(0)));
            const roundedPoint2 = coords.map(coord => parseFloat(coord.toFixed(0)));
            console.log( roundedPoint1?.[0] , roundedPoint2[0], roundedPoint1?.[1] , roundedPoint2[1]);
            return roundedPoint1?.[0] === roundedPoint2[0] && roundedPoint1?.[1] === roundedPoint2[1]

            // point?.coords?.[0] === coords[0] && point?.coords?.[1] === coords[1]
        });
    };


    const handleRedo = () => {
        if (redoData?.length > 0) {
            const lastObj = redoData[redoData.length - 1];
            if (lastObj.type !== "Point") {
                let removegraphData = redoData.slice(-3);
                setGraphData((prevData) => {
                    const updateData = [...prevData, ...removegraphData];

                    return updateData;
                });
                let newgraphData = redoData.slice(0, redoData.length - 3);
                setRedoData(newgraphData);
            } else {
                let removegraphData = redoData.slice(-1);
                setGraphData((prevData) => {
                    const updateData = [...prevData, ...removegraphData];

                    return updateData;
                });
                let newgraphData = redoData.slice(0, graphData.length - 1);
                setRedoData(newgraphData);
            }
        }
    }

    const handleUndo = () => {
        if (graphData.length > 0) {
            const lastObj = graphData[graphData.length - 1];

            if (lastObj.type !== "Point") {
                let removegraphData = graphData.slice(-3);
                setRedoData((prevData) => {
                    const updateData = [...prevData, ...removegraphData];
                    return updateData;
                });
                let newgraphData = graphData.slice(0, graphData.length - 3);
                setGraphData(newgraphData);

            } else {
                let removegraphData = graphData.slice(-1);
                setRedoData((prevData) => {
                    const updateData = [...prevData, ...removegraphData];
                    return updateData;
                });
                let newgraphData = graphData.slice(0, graphData.length - 1);
                setGraphData(newgraphData);

            }
        }
    }

    const handleShowAnswer = () => {

        if (setShowAnswers) {
            setShowAnswers((prev) => !prev);
        } else {
            setLocalShowAnswer((prev) => !prev);
        }
    }

    return (
        <PreviewWrapper
            currentQuestion={currentQuestion}
            showAnswer={showAnswer ?? localShowAnswer}
            setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
            parentMode={parentMode}
            editMode={editMode}
            handleGoToEdit={handleGoToEditClick}
            handleBackToEdit={handleBackToEditClick}
        >
            <div>
                <div
                    className="mt-5 flex items-center p-5 justify-between"
                    style={{
                        height: '45px',
                        backgroundColor: '#eee',
                        borderRadius: '6px',
                    }}
                >
                    <div className="basis-1/4">
                        <div className="flex space-x-2  ">
                            {optionItems?.toolbar?.tools?.map((tool, index) => (
                                <>

                                    <button type="button" className={`mr-2 flex p-[5px] ${buttonOption === index ? "rounded-sm border-[#fa9b31] border-2 " : ""}`} onClick={(e) => {
                                        //console.log(optionItems["toolbar"]["tools"][0]);
                                        setSelectedButton(tool);
                                        setButtonOption(index);
                                        //handleMovePoints();
                                    }}>
                                        {tool === "Point" && <TbPoint className="mr-1" />}
                                        {tool === "Move" && <IoMdMove className="mr-1" />}
                                        {tool === "Circle" && <FaRegCircle className="mr-1" />}
                                        {tool}
                                    </button>
                                </>
                            ))}{/* 
                            <button type="button" className={`mr-2 flex p-[5px] ${buttonOption === "0" ? "rounded-sm border-[#fa9b31] border-2 " : ""}`} onClick={(e) => {
                                console.log(optionItems?.toolbar?.tools[0], " button 1 Selection");
                                setButtonOption('0');
                                setSelectedButton(optionItems?.toolbar?.tools[0])
                            }}>
                                {optionItems?.toolbar?.tools[0] === "Point" && <TbPoint className="mr-1" />}
                                {optionItems?.toolbar?.tools[0] === "Move" && <IoMdMove className="mr-1" />}
                                {optionItems?.toolbar?.tools[0] === "Circle" && <FaRegCircle className="mr-1" />}
                                {optionItems?.toolbar?.tools[0]}
                            </button>
                            <button type="button" className={`mr-2 flex p-[5px] ${buttonOption === "1" ? "rounded-sm border-[#fa9b31] border-2 " : ""}`} onClick={(e) => {
                                console.log(optionItems["toolbar"]["tools"][1]);
                                setButtonOption('1');
                                setSelectedButton(optionItems?.toolbar?.tools[1])
                            }}>
                                {optionItems?.toolbar?.tools[1] === "Point" && <TbPoint className="mr-1" />}
                                {optionItems?.toolbar?.tools[1] === "Move" && <IoMdMove className="mr-1" />}
                                {optionItems?.toolbar?.tools[1] === "Circle" && <FaRegCircle className="mr-1" />}
                                {optionItems?.toolbar?.tools[1]}</button> */}
                        </div>
                    </div>
                    <div className="basis-1/2 text-right">
                        <div className="flex space-x-2  justify-end">
                            <button type="button" className="mr-2 flex " disabled={graphData.length > 0 ? false : true} onClick={(e) => { handleUndo(); }}>
                                <IoIosUndo className="mr-1" />Undo
                            </button>
                            <button type="button" className="mr-2 flex " disabled={redoData?.length > 0 ? false : true} onClick={(e) => { handleRedo(); }} ><IoIosRedo className="mr-1" />Redo</button>
                            <button type="button" className="mr-2 flex "
                                onClick={(e) => {

                                    handleReset();
                                }}

                            ><RxReset className="mr-1" /> Reset</button>
                        </div>
                    </div>
                </div >
                <div
                    className="flex items-center justify-between mb-5"
                    style={{
                        borderRadius: '6px',
                    }}>

                    <div>
                        <div className="basis-full">
                            <div ref={boardRef} style={{ width: '800px', height: '600px', border: '1px solid black' }} />
                        </div>
                    </div>
                </div>
            </div>
        </PreviewWrapper>
    )

};

export default PreviewGraphingUnit;
