import { ResponsiveBar } from '@nivo/bar'
import { useCallback, useState } from 'react';

interface ChartGraphProps {
    activeTab: {value: any[], score: number | string},
    questionData: any,
    handleBarData: (newBarData: any[]) => void,
}

export default function ChartGraph({activeTab, questionData, handleBarData} : ChartGraphProps) {
    const {template_response} = questionData;
    const barData = activeTab?.value.map(point => ({...point, y: Number(point.y)}));
    const maxYValue = !isNaN(Number(template_response.max_y_value)) ? Number(template_response.max_y_value) : 100

    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragStartY, setDragStartY] = useState<number | null>(null);

    const handleDragStart = useCallback((event, index) => {
        // Capture the starting Y position of the mouse
        setDraggedIndex(index);
        setDragStartY(event.clientY);
    }, []);

    const handleDragMove = useCallback((event) => {
        if (draggedIndex !== null && dragStartY !== null) {
            const deltaY = dragStartY - event.clientY;
            const newBarData = activeTab.value.map((point, i) => {
                if (i === draggedIndex) {
                    const calculatedValue = Math.min(maxYValue, Math.max(0, Number(point.y) + deltaY * 0.1));
                    return {
                        ...point,
                        y: maxYValue >= 10 ? Math.round(calculatedValue) : calculatedValue, 
                    };
                }
                return point;
            })
            handleBarData(newBarData);
        }
    }, [draggedIndex, dragStartY]);

    const handleDragEnd = useCallback(() => {
        setDraggedIndex(null);
        setDragStartY(null);
    }, []);

  return (
    <div
        style={{
            border: '1px solid #D9D9D9',
            backgroundColor: '#f3f5f7',
            padding: '8px',
            marginBottom: '16px',
            width: '100%', 
            height: '400px',
        }}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
    >
        <ResponsiveBar
            data={barData}
            keys={["y"]}
            indexBy="x"
            margin={{top: 50, right: 130, bottom: 50, left: 60}}
            padding={0.4}
            valueScale={{type: "linear"}}
            colors="#3182CE"
            animate={true}
            enableLabel={true}
            label={(d) => `${d.value}`} // Display the y value as label
            labelSkipWidth={0} // Show labels even on small bars
            labelSkipHeight={0} // Show labels even on small bars
            labelTextColor="white" // Set the label color to white
            axisRight={null}
            minValue={0}
            maxValue={maxYValue}
            enableGridX={true}
            isInteractive={true}
            onClick={(e) => {
                console.log('e =', e)
            }}
            
            axisTop= {{
                tickSize: 20,
                tickPadding: 5,
                tickRotation: 0,
                legend: template_response?.chartTitle,
                legendPosition: "middle",
                legendOffset: -40,
                // truncateTickAt: 0,
                renderTick: (props) => <h2 className='text-blue-500'>{props.value}</h2>
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: template_response?.yLabel || "",
                legendPosition: "middle",
                legendOffset: -40,
            }}
            axisBottom={{
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: template_response?.xLabel || '',
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0,
            }}
            layers={[
                'grid',
                'axes',
                'bars',
                ({ bars }) =>
                    bars.map((bar, index) => {
                        // If y value is 0, give the bar a minimum height for visibility
                        const minBarHeight = 10; // Minimum height to ensure visibility
                        const barHeight = bar.height > 0 ? bar.height : minBarHeight;

                        return (
                            <g key={bar.key} transform={`translate(${bar.x}, ${bar.y - (bar.height === 0 ? minBarHeight : 0)})`}>
                                <rect
                                    width={bar.width}
                                    height={barHeight}
                                    fill={bar.color}
                                    onMouseDown={(event) => handleDragStart(event, index)} // Start dragging
                                    style={{ cursor: 'grab' }}
                                />
                                {/* Ensure labels are visible on top of the bars */}
                                <text
                                    x={bar.width / 2}
                                    y={barHeight > minBarHeight ? barHeight / 2 : -10} // Adjust label position
                                    textAnchor="middle"
                                    dominantBaseline="central"
                                    fill="white"
                                    style={{ pointerEvents: 'none' }} // Make sure text is not interactive
                                >
                                    {bar?.data?.value}
                                </text>
                            </g>
                        );
                    }),
            ]}
        />
    </div>
  )
}
