const ChartQuestionTypes = Object.freeze({
    BARCHART: 'bc',
    LINECHART: 'lc',
    HISTOGRAM: 'his',
    DOTPLOT: 'dotplot',
    LINEPLOT: 'lineplot',
});

export {
    ChartQuestionTypes,
}